body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background: #000000;
  height: 100vh;
}

#online_tickets_span > span {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

#online_tickets_span2 > span {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

#footer_shadow {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: linear-gradient(transparent 0%, #000000);
}

@media (min-width: 1281px) and (max-width: 1961px) {
  #footer_shadow {
    height: 310px;
  }
}
@media (min-width: 961px) and (max-width: 1281px) {
  #footer_shadow {
    height: 250px;
  }
}
@media (min-width: 140px) and (max-width: 960px) {
  #footer_shadow {
    height: 150px;
  }
}

.square::before {
  color: blue;
  transition: none !important;
}
